<template>
  <div>
    <SignInLayout v-if="tab === 'login'">
      <template v-slot:title>Connectez-vous à votre compte</template>
      <template v-slot:subtitle>
        <p class="mt-2 text-center text-sm text-gray-600 dark:text-gray-200">
          Ou
          {{ ' ' }}
          <button @click.prevent="tab = 'register'" class="font-medium text-indigo-600 hover:text-indigo-500 dark:text-indigo-400">
            Inscrivez-vous
          </button>
        </p>
      </template>
      <template v-slot:form>
        <LoginForm @success="loginSuccess"/>
      </template>
    </SignInLayout>
    <SignInLayout v-if="tab === 'register'">
      <template v-slot:title>Créer un compte</template>
      <template v-slot:subtitle>
        <p class="mt-2 text-center text-sm text-gray-600">
          Ou
          {{ ' ' }}
          <button @click.prevent="tab = 'login'" class="font-medium text-indigo-600 hover:text-indigo-500">
            Connectez-vous
          </button>
        </p>
      </template>
      <template v-slot:form>
        <RegisterForm/>
      </template>
    </SignInLayout>
    <SignInLayout v-if="tab === 'forgot'">
      <template v-slot:title>Oublie de mot de passe</template>
      <template v-slot:subtitle>
        <p class="mt-2 text-center text-sm text-gray-600">
          Ou
          {{ ' ' }}
          <button @click.prevent="tab = 'login'" class="font-medium text-indigo-600 hover:text-indigo-500">
            Connectez-vous
          </button>
        </p>
      </template>
      <template v-slot:form>
        <RegisterForm/>
      </template>
    </SignInLayout>
  </div>
</template>
<script setup>
import SignInLayout from "~/components/SignInLayout.vue";
import LoginForm from "~/components/user/LoginForm.vue";
import RegisterForm from "~/components/user/RegisterForm.vue";
import {toRef, ref, watch} from 'vue';

const props = defineProps({
  defaultTab: {type: String, default: () => 'login'},
})

const emit = defineEmits(['loginSuccess', 'registerSuccess', 'forgotSuccess']);

const defaultTab = toRef(props, 'defaultTab');
const tab = ref(defaultTab.value+'')
watch(defaultTab, (t) => tab.value = t);


const loginSuccess = () => {
  emit('loginSuccess')
}
const registerSuccess = () => {
  emit('registerSuccess');
}
const forgotSuccess = () => {
  emit('forgotSuccess');
}
</script>
